@use 'abstracts/variables' as v;
@use 'abstracts/mediaQueries' as mq;
@use 'abstracts/mixins' as m;

.dashboard-projects-paginator,
.food-certificate-paginator {
  .mat-paginator-outer-container {
    .mat-paginator-container {
      justify-content: center;

      .mat-paginator-range-actions {
        button {
          padding: 0;
          width: 1.3rem;
          height: 1.3rem;
          line-height: 1.3rem;
          text-align: center;
          vertical-align: middle;
          font-size: v.$font-size-xxs3;

          &.mat-paginator-navigation-previous,
          &.mat-paginator-navigation-next {
            color: v.$brand-dark-grey;

            .mat-button-wrapper {
              display: inline-block;
              height: 1.3rem;
              line-height: 1.3rem;
              text-align: center;
              vertical-align: middle;

              .mat-paginator-icon {
                display: block;
                height: 1.3rem;
                width: 1.3rem;
              }
            }

            &.mat-button-disabled {
              display: none;
            }

            &:hover {
              color: v.$brand-dark-grey;
              background: transparent !important;
              background-color: transparent !important;
            }
          }

          &.custom-paginator-pagebutton {
            background-color: transparent;
            border-radius: v.$button-border-radius-full;
            color: v.$brand-standard-blue;
            margin-left: 4px;
            margin-right: 4px;

            &.current-page,
            &.current-page.disabled {
              background-color: v.$interaction-default;
              color: v.$white;
            }

            &:hover {
              color: v.$brand-dark-grey;
            }
          }
        }
      }
    }
  }
}

.dashboard-projects-paginator {
  margin-top: 1rem;
}
